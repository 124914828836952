var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "商品名称" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "商品ID" },
                    model: {
                      value: _vm.searchInfo.thirdProductId,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "thirdProductId", $$v)
                      },
                      expression: "searchInfo.thirdProductId"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择分类" },
                      model: {
                        value: _vm.searchInfo.status,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "status", $$v)
                        },
                        expression: "searchInfo.status"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "0",
                        attrs: { label: "全部", value: "0" }
                      }),
                      _c("el-option", {
                        key: "1",
                        attrs: { label: "上架", value: "1" }
                      }),
                      _c("el-option", {
                        key: "-1",
                        attrs: { label: "下架", value: "-1" }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          },
          on: { "selection-change": _vm.handleSelectionChange }
        },
        [
          _c("el-table-column", { attrs: { label: "ID", prop: "ID" } }),
          _c("el-table-column", { attrs: { label: "商品标题", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "短标题", prop: "shortTitle" }
          }),
          _c("el-table-column", {
            attrs: { label: "主图", prop: "cover" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("el-image", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: {
                        src: scope.row.cover,
                        "preview-src-list": [scope.row.cover]
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "售价", prop: "price" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v("¥" + _vm._s(scope.row.price))])]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "状态", prop: "status" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-row",
                      [
                        _c("span", [_vm._v("状态：")]),
                        scope.row.status === 1
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("上架")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("下架")
                            ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("span", [_vm._v("售罄：")]),
                        scope.row.sellOut === true
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("是")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("否")
                            ])
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c("span", [_vm._v("虚拟：")]),
                        scope.row.virtual === true
                          ? _c("el-tag", { attrs: { type: "success" } }, [
                              _vm._v("是")
                            ])
                          : _c("el-tag", { attrs: { type: "danger" } }, [
                              _vm._v("否")
                            ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "商品ID", prop: "thirdProductId", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "skuId", prop: "thirdSkuId", width: "120" }
          }),
          _c("el-table-column", {
            attrs: { label: "是否发布", prop: "publish", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(_vm._s(_vm._f("formatBoolean")(scope.row.publish)))
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "按钮组" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.updatePubProducts(scope.row)
                          }
                        }
                      },
                      [_vm._v("变更")]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top", width: "160" },
                        model: {
                          value: scope.row.visible,
                          callback: function($$v) {
                            _vm.$set(scope.row, "visible", $$v)
                          },
                          expression: "scope.row.visible"
                        }
                      },
                      [
                        _c("p", [_vm._v("确定要删除吗？")]),
                        _c(
                          "div",
                          {
                            staticStyle: { "text-align": "right", margin: "0" }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function($event) {
                                    scope.row.visible = false
                                  }
                                }
                              },
                              [_vm._v("取消")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function($event) {
                                    return _vm.deletePubProducts(scope.row)
                                  }
                                }
                              },
                              [_vm._v("确定")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              type: "danger",
                              icon: "el-icon-delete",
                              size: "mini"
                            },
                            slot: "reference"
                          },
                          [_vm._v("删除")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "弹窗操作"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 15 } },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: {
                    model: _vm.formData,
                    rules: _vm.rules,
                    size: "medium",
                    "label-width": "150px"
                  }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品ID", prop: "thirdProductId" } },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请输入商品ID",
                              readonly: "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.thirdProductId,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "thirdProductId", $$v)
                              },
                              expression: "formData.thirdProductId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "skuId", prop: "thirdSkuId" } },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请输入skuId",
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.thirdSkuId,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "thirdSkuId", $$v)
                              },
                              expression: "formData.thirdSkuId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品标题", prop: "name" } },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请输入商品标题",
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品短标题", prop: "shortTitle" } },
                        [
                          _c("el-input", {
                            style: { width: "100%" },
                            attrs: {
                              placeholder: "请输入商品短标题",
                              clearable: ""
                            },
                            model: {
                              value: _vm.formData.shortTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "shortTitle", $$v)
                              },
                              expression: "formData.shortTitle"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "主图", prop: "cover", required: "" }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "cover",
                              attrs: {
                                action: _vm.coverAction,
                                "before-upload": _vm.coverBeforeUpload,
                                "list-type": "picture-card"
                              }
                            },
                            [
                              _vm.formData.cover
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.formData.cover }
                                  })
                                : _c("i", { staticClass: "el-icon-plus" })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "宣传画廊" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action: _vm.imagesAction,
                                limit: 5,
                                "file-list": _vm.galleryFileList,
                                "on-exceed": _vm.uploadOverrun,
                                "on-success": _vm.handleGalleryUrl,
                                "on-remove": _vm.handleRemove,
                                multiple: "",
                                accept: ".jpg,.jpeg,.png,.gif",
                                "list-type": "picture-card"
                              }
                            },
                            [_c("i", { staticClass: "el-icon-plus" })]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "价格", prop: "price" } },
                        [
                          _c(
                            "el-input",
                            {
                              style: { width: "100%" },
                              attrs: {
                                placeholder: "请输入价格",
                                clearable: ""
                              },
                              model: {
                                value: _vm.formData.price,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "price", $$v)
                                },
                                expression: "formData.price"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("元")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "市场价", prop: "marketingPrice" } },
                        [
                          _c(
                            "el-input",
                            {
                              style: { width: "100%" },
                              attrs: {
                                placeholder: "请输入市场价",
                                clearable: ""
                              },
                              model: {
                                value: _vm.formData.marketingPrice,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "marketingPrice", $$v)
                                },
                                expression: "formData.marketingPrice"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("元")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "上下架",
                            prop: "status",
                            required: ""
                          }
                        },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-text": "上架",
                              "inactive-text": "下架",
                              "active-value": 1,
                              "inactive-value": 0
                            },
                            model: {
                              value: _vm.formData.status,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "status", $$v)
                              },
                              expression: "formData.status"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "售罄",
                            prop: "sellOut",
                            required: ""
                          }
                        },
                        [
                          _c("el-switch", {
                            attrs: { "active-value": 1, "inactive-value": 0 },
                            model: {
                              value: _vm.formData.sellOut,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "sellOut", $$v)
                              },
                              expression: "formData.sellOut"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发布", prop: "publish" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.formData.publish,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "publish", $$v)
                              },
                              expression: "formData.publish"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }